import classNames from 'classnames';
import styles from './styles/LoginButtons.module.scss';
import LoginButton from "./LoginButton";
import {useContext} from "react";
import {StoreContext} from "../../../../stores/StoreLoader";

const loginButtonsContainerClassName = classNames({
    [styles.loginButtonsContainer]: true,
});

export default function LoginButtons() {
    const {organizationStore} = useContext(StoreContext);

    return (
        <div>
            <ul className={loginButtonsContainerClassName}>
                {
                    ['google',
                        'office',
                        'facebook', 'passport'].filter(item => {
                            return item === "passport" ? !!organizationStore.organization.oneroster_id : true;
                    }).map((item, i) => {
                        return (
                            <li key={i}>
                                <LoginButton type={item}/>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}
